#leadsPage{
    .leadContent{
        margin-top: 24px;
        .MuiSwitch-root{
            overflow: unset;
        }
        .headerBlock{
            .headerText{
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                color: #232323;
            }
            .addLeadBtn button{
                float: right;
                padding: 9px 16px;
                text-transform: none;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                background-color: #02a5ba;
                border: 1px solid #02a5ba;
                margin-left: 8px;
                &:hover{
                    background: #fff;
                    color: #02a5ba;
                    border: 1px solid #02a5ba;
                }
            }
        }
        .leadTableBtns{
            .viewBtn{
                color: #4caf50;
                margin-right: 10px;
                border: 1px solid #4caf50;
                border-radius: 6px;
                font-size: 22px;
                padding: 4px 6px;
                cursor: pointer;
                &:hover{
                    background: #4caf50;
                    color: #fff;
                }
            }
            .editBtn{
                color: #e91e63;
                border: 1px solid #e91e63;
                border-radius: 6px;
                font-size: 22px;
                padding: 4px 6px;
                cursor: pointer;
                &:hover{
                    background: #e91e63;
                    color: #fff;
                }
            }
        }
    }
    .leadTableViewSec{
        padding: 0 24px;
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.02);
        margin-top: 20px;
        .tableHeaderBlock{
            padding: 10px 0 0 0;
            .FiltersLeadBtn{
                align-self: flex-end;
                .applyFilterBtn{
                    float: right;
                    padding: 9px 16px;
                    text-transform: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #e91e63;
                    margin-left: 8px;
                    border: 1px solid #e91e63;
                    &:hover{
                        background: #e91e63;
                        color: #fff;
                    }
                }
                .clearFilterBtn{
                    float: right;
                    padding: 9px 16px;
                    text-transform: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #e91e63;
                    margin-left: 8px;
                    border: 1px solid #e91e63;
                    &:hover{
                        background: #e91e63;
                        color: #fff;
                    }
                }
                .exportExcel{
                    float: right;
                    padding: 9px 16px;
                    text-transform: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #e91e63;
                    margin-left: 8px;
                    border: 1px solid #e91e63;
                    &:hover{
                        background: #e91e63;
                        color: #fff;
                    }
                }
            }
        }
        .tableBlock{
            margin-top: 12px;
            .rdt_TableHeadRow{
                background: #F8F8F8;
                border: 1px solid #DFDFDF;
                box-sizing: border-box;
            }
            .rdt_TableCol{
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                color: #535359;
            }
            .rdt_TableRow{
                font-style: normal;
                font-size: 14px;
                color: #0E0E0E;
                padding: 12px 0px;
                border-bottom: 1px solid #efeff0;
            }
        }
    }
}

#leadsFilers{
        .filterfields{
            margin-bottom: 20px;
        }
        .filterApplyBtn{
            float: right;
            padding: 9px 16px;
            text-transform: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #02a5ba;
            margin-left: 8px;
            border: 1px solid #02a5ba;
            margin-top: 10%;
        }
}

.addNewLeadDialogBlock{
    form{
        margin-bottom: 15px;
    }
    .formBlockHeader{
        margin: auto 10px;
        color: #666363c7;
        font-weight: 400;
        font-size: 13px;
    }
}
.addNewLeadModalHeader{
    .addLeadModalHeaderTitle{
        font-weight: 900;
        font-size: 20px;
        line-height: 24px;
        color: #232323;
    }
    .addLeadModalBtnsBlock{
        text-align: end;
        .cancelBtn{
            background: #9e9e9e;
            color: #fff;
            margin-left: 15px;
            border: unset;
            font-size: 14px;
            font-weight: 700;
        }
        .saveBtn{
            background: #e91e63;
            color: #fff;
            margin-left: 15px;
            border: unset;
            font-size: 14px;
            font-weight: 700;
            .MuiCircularProgress-root{
                margin-right: 10px;
                color: #fff;
            }
        }
    }
}


#leadExpandView{
    padding: 20px 0;
    margin: 0px 59px;
    .leadViewDetails{
        display: grid;
        margin-bottom: 5%;
        label{
            font-size: 20px;
            font-weight: 600;
            color: #3b3535bf;
            margin-bottom: 6px;
        }
        span{
            font-size: 15px;
        }
    }
    .leadViewDetailsLeftBlock{
        
    }
    .leadViewDetailsRightBlock{
       
    }

    .leadViewDetailsQuestionBlock{
        padding: 25px;
        border: 1px solid #9c949533;
        background: #f9fbfba8;
        border-radius: 13px;
        .leadViewDetailsQuestion{
            display: grid;
            margin-bottom: 20px;
            .title{
                margin-bottom:10px;
            }
        }
    }


    .leadStatusSection{
        padding: 12px;
        border: 1px solid #9c949533;
        background: #eaeaea94;
        border-radius: 13px;
        .notesHeader{
            margin-bottom: 10px;
            margin-left: 4px;
            font-weight: 700;
        }
        .leadStatusBlock{
            padding: 10px 3px;
            background: #fff;
            border-radius: 10px;
            position: relative;
            .previousNotesBlock{
                max-height: 200px;
                overflow: overlay;
                margin-bottom: 15px;
                padding-left: 10px;
                padding-right: 20px;
                &::-webkit-scrollbar {
                    width: 7px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                background: #f1f1f1; 
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #e91e63;
                    border-radius: 26px;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                background: #555; 
                }
                .MuiCircularProgress-root{
                    position: relative;
                    z-index: 1000;
                    left: calc(55% - 50px);
                    padding: 79px 0;
                    color: #e91e63;
                }
                .leadNotesVIew{
                    margin-top: 10px;
                    border-bottom: 1px solid #dedede69;
                    padding-bottom: 5px;
                    .title{
                        font-size: 17px;
                        margin-bottom: 2px;
                        font-weight: 600;
                    }
                    .updatedOn{
                        margin-bottom: 3px;
                        font-size: 13px;
                    }
                    .notes{
                        font-size: 16px;
                    }
                }
            }
            .leadViewDetailsQuestion{
                display: grid;
                margin-bottom: 20px;
                .title{
                    margin-bottom:10px;
                }
                svg{
                    align-self: center;
                    color: #ffffff;
                    background: #e91e63;
                    border: 1px solid #e91e63;
                    border-radius: 20px;
                    padding: 8px 8px;
                    margin-left: 8px;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .leadViewDetailsQuestion:last-child {
                margin-bottom: 0px;
            }
        }
    }
}


#recodingView{
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 10%;
}

.tableEditBtns{
    display: flex;
    button{
        margin-right: 10px;
        &:focus{
            outline: unset;
        }
    }
    .editBtn{
        background: #02a5ba;
        color: #fff;
        padding: 6px 10px;
        border: 1px solid #02a5ba;
        border-radius: 6px;
        font-size: 15px;
        cursor: pointer;
    }
    .updateBtn{
        background: #27bf00;
        color: #fff;
        padding: 6px 10px;
        border: 1px solid #27bf00;
        border-radius: 6px;
        font-size: 15px;
        cursor: pointer;
    }
    .cancelBtn{
        background: #5c5f5f;
        color: #fff;
        padding: 6px 10px;
        border: 1px solid #5c5f5f;
        border-radius: 6px;
        font-size: 15px;
        cursor: pointer;
    }
}

.clipboardCopyBtn{
    border: 1px solid #e91e63;
    background: transparent;
    padding: 9px;
    color: #e91e63;
    font-size: 18px;
    &:focus{
        outline: unset;
    }
}


.enableAutoLoadLeadTable{
    padding: 0px 20px 0px 9px;
    color: #9e9e9e;
    margin-left: 25px !important;
    margin-top: 4px;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
}


.cityColorChange{
    .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline{
        height: 20px;
        border-radius: 20px;
        &:before{
            border: unset;
        }
    }
    .MuiSelect-select.MuiSelect-selectMenu{
        content-visibility: hidden;
        padding: 3px 5px 0 15px;
    }
    
}

.cityColorOption {
    padding: 9px 27px;
    border-bottom: 1px solid #9e9e9e9e;
    color: #717171;
    cursor: pointer;
    &:focus{
        outline: unset;
    }
}
.cityColorOption:first-child {
    padding-top: 5px;
}
.cityColorOption:last-child {
    border-bottom: unset;
    padding-bottom: 0;
}

#whileFont{
    color: #fff;
    padding: 8px 23px 8px 7px;
    &::after {
        font-family: 'Font Awesome\ 5 Free';
        content: "\f107";
        position: absolute;
        font-weight: 600; 
        right: 5px;
        top: 7px;
        color: #C5C7D2;
        background: white;
    }
    option{
        color: #000;
    }
}

.autoLoadSetBtn{
    background: #e45464 !important;
    color: #fff !important;
    font-weight: 500 !important;
    margin-top: 6px !important;
    margin-left: 7px !important;
}

#updateStatusActionBtn{
    padding: 0px;
    
    #actionBtn{
        border: 1px solid;
        background-color: #e91e63;
    border: 1px solid #e91e63;
        color: #fff;
        padding: 5px 13px;
        border-radius: 6px;
        .statusBtnText{
            font-size: large;
        }
        .statusBtnIcon{
            margin-top: 21px;
            vertical-align: middle;
        }
        
    }
    
                
                
}


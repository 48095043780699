@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.App {
  text-align: center;
}

.main-page {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100vh;
}

.top-section {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  background: rgb(236, 236, 236);
}

.bottom-section {
  display: flex;
  flex: 3 1;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
}

.bottom-section button {
  height: 20%;
  width: 20%;
  font-size: 30px;
}



/*** Login Form Start ***/
.login_container_block:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background-image: url(/static/media/login_bg.7756dc73.png); }

.login_container_block .box {
  background: #FFFFFF;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.082);
  border-radius: 11px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.login_container_block .box-header {
  padding: 43px 12px; }
  .login_container_block .box-header .logo {
    text-align: center; }
    .login_container_block .box-header .logo img {
      width: 350px; }
  .login_container_block .box-header h1 {
    text-align: center; }

.login_container_block .box-form {
  padding: 15px 32px; }
  .login_container_block .box-form .material_input {
    margin-bottom: 20px; }
    .login_container_block .box-form .material_input label.Mui-focused {
      color: #07a8bd; }
    .login_container_block .box-form .material_input .MuiInput-underline:after {
      border-bottom: #07a8bd; }
    .login_container_block .box-form .material_input .MuiOutlinedInput-root:hover fieldset {
      border-color: #07a8bd; }
    .login_container_block .box-form .material_input .MuiOutlinedInput-root.Mui-focused fieldset {
      border-color: #07a8bd; }
  .login_container_block .box-form .remember_forgot {
    display: flex; }
    .login_container_block .box-form .remember_forgot .forgot_password {
      align-self: center;
      width: 66%;
      text-align: right; }
  .login_container_block .box-form .formBtn {
    text-align: center;
    padding: 17px 51px; }
    .login_container_block .box-form .formBtn .submitBtn {
      border: 1px solid #07a8bd;
      color: #07a8bd;
      padding: 15px 16px; }
      .login_container_block .box-form .formBtn .submitBtn:hover {
        background: #07a8bd;
        color: #fff; }
      .login_container_block .box-form .formBtn .submitBtn:active {
        background: #07a8bd;
        color: #fff; }

@media only screen and (max-width: 1024px) {
  .login_container_block .box {
    left: 48%;
    width: 92%; } }

/*** Login Form End ***/

.bodyContent {
  margin-top: 3%;
  padding: 24px 24px;
  background-color: #b2cacf14; }

.header .MuiAppBar-colorPrimary {
  background-color: #fff;
  color: #535359;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02); }

.drawerRight {
  padding: 24px 24px;
  width: 346px; }
  .drawerRight .filterHeading {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #535359;
    margin-bottom: 41px; }

#navBarMenus #appbar-collapse {
  margin-left: 90px; }

#navBarMenus #appbar-collapse a {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #535359;
  margin-right: 30px;
  text-decoration: none; }

#navBarMenus #appbar-collapse a[aria-current='page'] {
  color: #e91e63; }

header .profileMenuBtn {
  width: 100%;
  text-align: right; }

.profileIcons {
  min-width: 32px !important; }

.backDropLoader {
  z-index: 1111 !important; }

.navLinkBar {
  display: contents;
  text-decoration: none;
  color: unset; }

.loginErrorMsg {
  text-align: center;
  color: #e91e63; }

#statusConfigurationPage #uploadBtn {
  margin-top: 4%;
  text-align: center;
  border: 1px dashed #000;
  padding: 24px; }

#listOfImagesAdded {
  display: flex;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px; }
  #listOfImagesAdded .image-item {
    margin-right: 10px;
    border: 1px solid #ff9800;
    padding: 10px; }
    #listOfImagesAdded .image-item img {
      height: 80px;
      width: 123px; }
    #listOfImagesAdded .image-item .image-item__btn-wrapper {
      margin-top: 10px; }
    #listOfImagesAdded .image-item .replaceBtn {
      border: 1px solid #607d8b;
      background: #fff;
      padding: 6px;
      font-size: 13px;
      color: #607d8b;
      margin-left: 5px; }
    #listOfImagesAdded .image-item .removeBtn {
      border: 1px solid red;
      background: #fff;
      padding: 6px;
      font-size: 13px;
      color: red;
      margin-left: 5px; }

#uploadImgCarBtns .uploadImgBtn {
  border: 1px solid #02a5ba;
  padding: 12px;
  font-size: 15px;
  color: #fff;
  background: #02a5ba;
  border-radius: 6PX;
  cursor: pointer; }

#uploadImgCarBtns .removeImgBtn {
  border: 1px solid #f44336;
  padding: 12px;
  font-size: 15px;
  color: #fff;
  background: #f44336;
  border-radius: 6PX;
  margin-left: 10PX;
  cursor: pointer; }

.createNewCarBtn {
  margin-top: 3% !important;
  background-color: #00a4ba !important;
  color: white !important;
  padding: 11px !important;
  font-weight: 400 !important; }

.goBackNewCarBtn {
  margin-top: 3% !important;
  background-color: #f44336 !important;
  color: white !important;
  padding: 11px !important;
  font-weight: 400 !important;
  margin-right: 5px !important; }

#leadsPage .leadContent {
  margin-top: 24px; }
  #leadsPage .leadContent .MuiSwitch-root {
    overflow: unset; }
  #leadsPage .leadContent .headerBlock .headerText {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #232323; }
  #leadsPage .leadContent .headerBlock .addLeadBtn button {
    float: right;
    padding: 9px 16px;
    text-transform: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background-color: #02a5ba;
    border: 1px solid #02a5ba;
    margin-left: 8px; }
    #leadsPage .leadContent .headerBlock .addLeadBtn button:hover {
      background: #fff;
      color: #02a5ba;
      border: 1px solid #02a5ba; }
  #leadsPage .leadContent .leadTableBtns .viewBtn {
    color: #4caf50;
    margin-right: 10px;
    border: 1px solid #4caf50;
    border-radius: 6px;
    font-size: 22px;
    padding: 4px 6px;
    cursor: pointer; }
    #leadsPage .leadContent .leadTableBtns .viewBtn:hover {
      background: #4caf50;
      color: #fff; }
  #leadsPage .leadContent .leadTableBtns .editBtn {
    color: #e91e63;
    border: 1px solid #e91e63;
    border-radius: 6px;
    font-size: 22px;
    padding: 4px 6px;
    cursor: pointer; }
    #leadsPage .leadContent .leadTableBtns .editBtn:hover {
      background: #e91e63;
      color: #fff; }

#leadsPage .leadTableViewSec {
  padding: 0 24px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.02);
  margin-top: 20px; }
  #leadsPage .leadTableViewSec .tableHeaderBlock {
    padding: 10px 0 0 0; }
    #leadsPage .leadTableViewSec .tableHeaderBlock .FiltersLeadBtn {
      align-self: flex-end; }
      #leadsPage .leadTableViewSec .tableHeaderBlock .FiltersLeadBtn .applyFilterBtn {
        float: right;
        padding: 9px 16px;
        text-transform: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #e91e63;
        margin-left: 8px;
        border: 1px solid #e91e63; }
        #leadsPage .leadTableViewSec .tableHeaderBlock .FiltersLeadBtn .applyFilterBtn:hover {
          background: #e91e63;
          color: #fff; }
      #leadsPage .leadTableViewSec .tableHeaderBlock .FiltersLeadBtn .clearFilterBtn {
        float: right;
        padding: 9px 16px;
        text-transform: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #e91e63;
        margin-left: 8px;
        border: 1px solid #e91e63; }
        #leadsPage .leadTableViewSec .tableHeaderBlock .FiltersLeadBtn .clearFilterBtn:hover {
          background: #e91e63;
          color: #fff; }
      #leadsPage .leadTableViewSec .tableHeaderBlock .FiltersLeadBtn .exportExcel {
        float: right;
        padding: 9px 16px;
        text-transform: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #e91e63;
        margin-left: 8px;
        border: 1px solid #e91e63; }
        #leadsPage .leadTableViewSec .tableHeaderBlock .FiltersLeadBtn .exportExcel:hover {
          background: #e91e63;
          color: #fff; }
  #leadsPage .leadTableViewSec .tableBlock {
    margin-top: 12px; }
    #leadsPage .leadTableViewSec .tableBlock .rdt_TableHeadRow {
      background: #F8F8F8;
      border: 1px solid #DFDFDF;
      box-sizing: border-box; }
    #leadsPage .leadTableViewSec .tableBlock .rdt_TableCol {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #535359; }
    #leadsPage .leadTableViewSec .tableBlock .rdt_TableRow {
      font-style: normal;
      font-size: 14px;
      color: #0E0E0E;
      padding: 12px 0px;
      border-bottom: 1px solid #efeff0; }

#leadsFilers .filterfields {
  margin-bottom: 20px; }

#leadsFilers .filterApplyBtn {
  float: right;
  padding: 9px 16px;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #02a5ba;
  margin-left: 8px;
  border: 1px solid #02a5ba;
  margin-top: 10%; }

.addNewLeadDialogBlock form {
  margin-bottom: 15px; }

.addNewLeadDialogBlock .formBlockHeader {
  margin: auto 10px;
  color: #666363c7;
  font-weight: 400;
  font-size: 13px; }

.addNewLeadModalHeader .addLeadModalHeaderTitle {
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #232323; }

.addNewLeadModalHeader .addLeadModalBtnsBlock {
  text-align: end; }
  .addNewLeadModalHeader .addLeadModalBtnsBlock .cancelBtn {
    background: #9e9e9e;
    color: #fff;
    margin-left: 15px;
    border: unset;
    font-size: 14px;
    font-weight: 700; }
  .addNewLeadModalHeader .addLeadModalBtnsBlock .saveBtn {
    background: #e91e63;
    color: #fff;
    margin-left: 15px;
    border: unset;
    font-size: 14px;
    font-weight: 700; }
    .addNewLeadModalHeader .addLeadModalBtnsBlock .saveBtn .MuiCircularProgress-root {
      margin-right: 10px;
      color: #fff; }

#leadExpandView {
  padding: 20px 0;
  margin: 0px 59px; }
  #leadExpandView .leadViewDetails {
    display: grid;
    margin-bottom: 5%; }
    #leadExpandView .leadViewDetails label {
      font-size: 20px;
      font-weight: 600;
      color: #3b3535bf;
      margin-bottom: 6px; }
    #leadExpandView .leadViewDetails span {
      font-size: 15px; }
  #leadExpandView .leadViewDetailsQuestionBlock {
    padding: 25px;
    border: 1px solid #9c949533;
    background: #f9fbfba8;
    border-radius: 13px; }
    #leadExpandView .leadViewDetailsQuestionBlock .leadViewDetailsQuestion {
      display: grid;
      margin-bottom: 20px; }
      #leadExpandView .leadViewDetailsQuestionBlock .leadViewDetailsQuestion .title {
        margin-bottom: 10px; }
  #leadExpandView .leadStatusSection {
    padding: 12px;
    border: 1px solid #9c949533;
    background: #eaeaea94;
    border-radius: 13px; }
    #leadExpandView .leadStatusSection .notesHeader {
      margin-bottom: 10px;
      margin-left: 4px;
      font-weight: 700; }
    #leadExpandView .leadStatusSection .leadStatusBlock {
      padding: 10px 3px;
      background: #fff;
      border-radius: 10px;
      position: relative; }
      #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock {
        max-height: 200px;
        overflow: overlay;
        margin-bottom: 15px;
        padding-left: 10px;
        padding-right: 20px;
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock::-webkit-scrollbar {
          width: 7px; }
        #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock::-webkit-scrollbar-track {
          background: #f1f1f1; }
        #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock::-webkit-scrollbar-thumb {
          background: #e91e63;
          border-radius: 26px; }
        #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock::-webkit-scrollbar-thumb:hover {
          background: #555; }
        #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock .MuiCircularProgress-root {
          position: relative;
          z-index: 1000;
          left: calc(55% - 50px);
          padding: 79px 0;
          color: #e91e63; }
        #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock .leadNotesVIew {
          margin-top: 10px;
          border-bottom: 1px solid #dedede69;
          padding-bottom: 5px; }
          #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock .leadNotesVIew .title {
            font-size: 17px;
            margin-bottom: 2px;
            font-weight: 600; }
          #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock .leadNotesVIew .updatedOn {
            margin-bottom: 3px;
            font-size: 13px; }
          #leadExpandView .leadStatusSection .leadStatusBlock .previousNotesBlock .leadNotesVIew .notes {
            font-size: 16px; }
      #leadExpandView .leadStatusSection .leadStatusBlock .leadViewDetailsQuestion {
        display: grid;
        margin-bottom: 20px; }
        #leadExpandView .leadStatusSection .leadStatusBlock .leadViewDetailsQuestion .title {
          margin-bottom: 10px; }
        #leadExpandView .leadStatusSection .leadStatusBlock .leadViewDetailsQuestion svg {
          align-self: center;
          color: #ffffff;
          background: #e91e63;
          border: 1px solid #e91e63;
          border-radius: 20px;
          padding: 8px 8px;
          margin-left: 8px;
          font-size: 16px;
          cursor: pointer; }
      #leadExpandView .leadStatusSection .leadStatusBlock .leadViewDetailsQuestion:last-child {
        margin-bottom: 0px; }

#recodingView {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 10%; }

.tableEditBtns {
  display: flex; }
  .tableEditBtns button {
    margin-right: 10px; }
    .tableEditBtns button:focus {
      outline: unset; }
  .tableEditBtns .editBtn {
    background: #02a5ba;
    color: #fff;
    padding: 6px 10px;
    border: 1px solid #02a5ba;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer; }
  .tableEditBtns .updateBtn {
    background: #27bf00;
    color: #fff;
    padding: 6px 10px;
    border: 1px solid #27bf00;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer; }
  .tableEditBtns .cancelBtn {
    background: #5c5f5f;
    color: #fff;
    padding: 6px 10px;
    border: 1px solid #5c5f5f;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer; }

.clipboardCopyBtn {
  border: 1px solid #e91e63;
  background: transparent;
  padding: 9px;
  color: #e91e63;
  font-size: 18px; }
  .clipboardCopyBtn:focus {
    outline: unset; }

.enableAutoLoadLeadTable {
  padding: 0px 20px 0px 9px;
  color: #9e9e9e;
  margin-left: 25px !important;
  margin-top: 4px;
  border: 1px solid #9e9e9e;
  border-radius: 4px; }

.cityColorChange .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline {
  height: 20px;
  border-radius: 20px; }
  .cityColorChange .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before {
    border: unset; }

.cityColorChange .MuiSelect-select.MuiSelect-selectMenu {
  content-visibility: hidden;
  padding: 3px 5px 0 15px; }

.cityColorOption {
  padding: 9px 27px;
  border-bottom: 1px solid #9e9e9e9e;
  color: #717171;
  cursor: pointer; }
  .cityColorOption:focus {
    outline: unset; }

.cityColorOption:first-child {
  padding-top: 5px; }

.cityColorOption:last-child {
  border-bottom: unset;
  padding-bottom: 0; }

#whileFont {
  color: #fff;
  padding: 8px 23px 8px 7px; }
  #whileFont::after {
    font-family: 'Font Awesome\ 5 Free';
    content: "\f107";
    position: absolute;
    font-weight: 600;
    right: 5px;
    top: 7px;
    color: #C5C7D2;
    background: white; }
  #whileFont option {
    color: #000; }

.autoLoadSetBtn {
  background: #e45464 !important;
  color: #fff !important;
  font-weight: 500 !important;
  margin-top: 6px !important;
  margin-left: 7px !important; }

#updateStatusActionBtn {
  padding: 0px; }
  #updateStatusActionBtn #actionBtn {
    border: 1px solid;
    background-color: #e91e63;
    border: 1px solid #e91e63;
    color: #fff;
    padding: 5px 13px;
    border-radius: 6px; }
    #updateStatusActionBtn #actionBtn .statusBtnText {
      font-size: large; }
    #updateStatusActionBtn #actionBtn .statusBtnIcon {
      margin-top: 21px;
      vertical-align: middle; }

#profilePage #profilePageContent {
  margin-top: 5%; }
  #profilePage #profilePageContent .leftSide {
    padding: 20px;
    background: #fff; }
    #profilePage #profilePageContent .leftSide .profileInfoHead {
      font-size: 21px;
      font-family: inherit;
      margin-bottom: 10%;
      font-weight: 700;
      color: #525252cf;
      text-align: center; }
    #profilePage #profilePageContent .leftSide .profileImg {
      text-align: center; }
      #profilePage #profilePageContent .leftSide .profileImg img {
        border: 4px solid #e91e63;
        border-radius: 50%;
        margin-bottom: 5%; }
    #profilePage #profilePageContent .leftSide .profileDetails {
      margin-top: 5%;
      text-align: center;
      font-size: 18px;
      color: #666666e3; }
  #profilePage #profilePageContent .rightSide {
    padding: 20px;
    background: #fff; }
    #profilePage #profilePageContent .rightSide .profileInfoHead {
      font-size: 21px;
      font-family: inherit;
      font-weight: 700;
      color: #525252cf; }
    #profilePage #profilePageContent .rightSide .profileTabs .MuiAppBar-colorPrimary {
      background: #e91e63; }
    #profilePage #profilePageContent .rightSide .profileTabs .MuiTab-root {
      font-weight: 600; }
    #profilePage #profilePageContent .rightSide .profileTabs .MuiTab-textColorInherit.Mui-selected {
      background: #fff;
      color: #e91e63; }
    #profilePage #profilePageContent .rightSide .profileTabs .personalInfoDet {
      margin-top: 2%; }
      #profilePage #profilePageContent .rightSide .profileTabs .personalInfoDet .profileDetHead {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10%; }
      #profilePage #profilePageContent .rightSide .profileTabs .personalInfoDet .profileDetText {
        margin-top: 1%;
        color: #727272;
        font-size: 18px;
        margin-bottom: 10%; }
    #profilePage #profilePageContent .rightSide .profileTabs .passwordSection {
      margin-top: 2%; }
      #profilePage #profilePageContent .rightSide .profileTabs .passwordSection .profileDetHead {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 13%; }
      #profilePage #profilePageContent .rightSide .profileTabs .passwordSection .profileDetText {
        margin-top: 1%;
        color: #727272;
        font-size: 18px;
        margin-bottom: 10%; }

#dashboardPage .countSection {
  margin-top: 3%; }
  #dashboardPage .countSection .countBlocks {
    background: #fff;
    padding: 20px;
    display: grid;
    place-items: center;
    box-shadow: 0px 6px 18px #c7c7c77a; }
    #dashboardPage .countSection .countBlocks span {
      margin: 3% auto; }


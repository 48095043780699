.bodyContent{
    margin-top:3%;
    padding: 24px 24px;
    background-color:#b2cacf14;
}

.header .MuiAppBar-colorPrimary{
    background-color: #fff;
    color: #535359;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.drawerRight{
    padding: 24px 24px;
    width: 346px;
    .filterHeading{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #535359;
        margin-bottom: 41px;
    }
}


#navBarMenus #appbar-collapse {
        margin-left: 90px;
    }
#navBarMenus #appbar-collapse a{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #535359;
    margin-right: 30px;
    text-decoration: none;
    
}
#navBarMenus #appbar-collapse a[aria-current='page']{
        color: #e91e63;
}
header .profileMenuBtn{
    width: 100%;
    text-align: right;
}

.profileIcons{
    min-width: 32px !important;
}

.backDropLoader{
    z-index: 1111 !important;
}

.navLinkBar{
    display: contents;
    text-decoration: none;
    color: unset;
}

.loginErrorMsg{
    text-align: center;
    color: #e91e63;
}


#statusConfigurationPage {
    #uploadBtn{
        margin-top: 4%;
        text-align: center;
        border: 1px dashed #000;
        padding: 24px
    }
}


#listOfImagesAdded{
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 15px;
    .image-item{
        margin-right: 10px;
        border: 1px solid #ff9800;
        padding: 10px;
        img{
            height: 80px;
            width: 123px;
        }
        .image-item__btn-wrapper{
            margin-top: 10px;
        }
        .replaceBtn{
            border: 1px solid #607d8b;
            background: #fff;
            padding: 6px;
            font-size: 13px;
            color: #607d8b;
            margin-left: 5px;
        }
        .removeBtn{
            border: 1px solid red;
            background: #fff;
            padding: 6px;
            font-size: 13px;
            color: red;
            margin-left: 5px;
        }
    }
}

#uploadImgCarBtns{
    .uploadImgBtn{
        border: 1px solid #02a5ba;
        padding: 12px;
        font-size: 15px;
        color: #fff;
        background: #02a5ba;
        border-radius: 6PX;
        cursor: pointer;
    }
    .removeImgBtn{
        border: 1px solid #f44336;
        padding: 12px;
        font-size: 15px;
        color: #fff;
        background: #f44336;
        border-radius: 6PX;
        margin-left: 10PX;
        cursor: pointer;
    }
}

.createNewCarBtn{
    margin-top: 3% !important;
    background-color: #00a4ba !important;
    color: white !important;
    padding: 11px !important;
    font-weight: 400 !important;
}

.goBackNewCarBtn{
    margin-top: 3% !important;
    background-color: #f44336 !important;
    color: white !important;
    padding: 11px !important;
    font-weight: 400 !important;
    margin-right: 5px !important;
}